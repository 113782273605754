header {
    position: relative;
    background-color:  #F1E5AC  ;
    color: white;
    z-index: 1;
    height: 19rem;
  }
  
  header .background-image {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    object-fit: cover;
    z-index: -1;
    opacity: 20%;
  }
  
  header h1 {
    z-index: 1;
    margin-top: 7rem;
    font-family: 'Montserrat', sans-serif;
    font-weight: 700;
    font-size: 48px;
    color: #a0ac00;
  }
  
  header .top-right-buttons {
    position: absolute;
    top: 10px;
    right: 20px;
    z-index: 1;
  }
  
  header .top-right-buttons .btn {
    font-family: 'Montserrat', sans-serif;
    font-weight: 500;
    font-size: 16px;
    color: #f9f9f9;
  }
  
  header .top-right-buttons .btn:hover {
    border-bottom: 2px solid #f9f9f9;
    color: #f9f9f9;
  }
  