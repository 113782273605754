/* MP3 Player Container */
.mp3-player {
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: #f9f9f9;
  border-radius: 8px;
  padding: 20px;
  width: 100%;
  max-width: 600px;
  margin: 20px auto;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

/* Category Selector */
.category-selector,
.folder-creation,
.existing-folders,
.file-upload {
  margin: 15px 0;
  width: 100%;
}

label {
  font-size: 1rem;
  font-family: 'Montserrat', sans-serif;
  color: #333;
  margin-bottom: 8px;
  display: block;
}

select,
input[type="text"],
.file-input {
  width: 100%;
  padding: 10px;
  border: 1px solid #00a99d;
  border-radius: 4px;
  font-size: 1rem;
  font-family: 'Montserrat', sans-serif;
  color: #333;
  margin-bottom: 10px;
  transition: border-color 0.3s ease;
}

select:focus,
input[type="text"]:focus,
.file-input:focus {
  border-color: #007d73;
  outline: none;
}

.folder-creation button {
  background-color: #00a99d;
  color: white;
  border: none;
  padding: 10px 15px;
  border-radius: 4px;
  font-size: 1rem;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.folder-creation button:hover {
  background-color: #007d73;
}

.file-upload {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.file-upload-label {
  font-size: 1rem;
  font-family: 'Montserrat', sans-serif;
  color: #333;
  margin-bottom: 10px;
}

.file-input {
  padding: 10px;
  border: 1px solid #00a99d;
  border-radius: 4px;
  font-size: 1rem;
  font-family: 'Montserrat', sans-serif;
  color: #333;
}

.metadata {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  padding: 15px;
  border-top: 2px solid #00a99d;
}

.track-info {
  text-align: center;
  margin-bottom: 10px;
}

.track-info .title {
  font-size: 1.2rem;
  font-weight: 500;
  color: #00a99d;
  font-family: 'Montserrat', sans-serif;
}

.track-info .artist {
  font-size: 1rem;
  color: #666;
  font-family: 'Montserrat', sans-serif;
}

.controls {
  display: flex;
  align-items: center;
  width: 100%;
}

.play-pause-btn {
  background-color: #00a99d;
  color: white;
  border: none;
  border-radius: 50%;
  width: 50px;
  height: 50px;
  font-size: 1.5rem;
  cursor: pointer;
  margin-right: 15px;
  transition: background-color 0.3s ease;
}

.play-pause-btn:hover {
  background-color: #007d73;
}

.progress-bar {
  flex-grow: 1;
  margin: 0 15px;
  height: 8px;
  background-color: #00a99d;
  border-radius: 5px;
  cursor: pointer;
}

.progress-bar::-webkit-slider-thumb {
  -webkit-appearance: none;
  appearance: none;
  width: 20px;
  height: 20px;
  border-radius: 50%;
  background-color: white;
  cursor: pointer;
}

.progress-bar::-moz-range-thumb {
  width: 20px;
  height: 20px;
  border-radius: 50%;
  background-color: white;
  cursor: pointer;
}

.time {
  font-size: 0.9rem;
  color: #666;
  font-family: 'Montserrat', sans-serif;
}
.toggle-create-folder {
  background-color: #00a99d;
  color: white;
  border: none;
  padding: 10px 15px;
  border-radius: 4px;
  font-size: 1rem;
  cursor: pointer;
  margin-top: 10px;
  transition: background-color 0.3s ease;
}

.toggle-create-folder:hover {
  background-color: #007d73;
}

.folder-creation {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 15px;
}

.folder-creation input {
  padding: 10px;
  border: 1px solid #00a99d;
  border-radius: 4px;
  font-size: 1rem;
  font-family: 'Montserrat', sans-serif;
  color: #333;
  margin-bottom: 10px;
}


