/* Navbar */
nav ul.nav-pills {
  border-bottom: 2px solid #dddddd;
}

nav ul.nav-pills .nav-link {
  margin-left: 50px;
  font-family: "Montserrat", sans-serif;
  font-weight: 500;
  font-size: 16px;
  background-color: #ffffffbd !important;
  color: #7c6700;
}

nav ul.nav-pills .nav-link.active {
  border-bottom: 2px solid #22a5ad;
  color: #00a99eaf;
}

nav ul.nav-pills .nav-link:hover {
  background-color: #f3f3f3 !important;
  color: #1e898fb2;
}

@media (max-width: 768px) {
  
nav ul.nav-pills .nav-link {
  font-size: 14px;
  align-items: center;
  
}
}
/* Audio Player */
.audio-player-fixed {
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100%;
  background-color: #fff;
  box-shadow: 0 -2px 10px rgba(0, 0, 0, 0.2);
  padding: 10px 20px;
  z-index: 1000;
}

audio {
  width: 100%;
  margin-top: 5px;
}

/* Track Info */
.track-info {
  font-family: "Montserrat", sans-serif;
  font-size: 16px;
  color: #333;
  margin-bottom: 5px;
}

.track-title {
  font-weight: bold;
  color: #22a5ad;
}

.track-artist {
  font-style: italic;
  color: #555;
}

/* Album List */
.album-list {
  flex: 1;
  min-width: 250px;
}

.album-list h2,
.album h2 {
  font-family: "Montserrat", sans-serif;
  font-weight: 700;
  color: #22a5ad;
  font-size: 24px;
  margin-bottom: 20px;
}

.album-list h3 {
  font-family: "Montserrat", sans-serif;
  font-weight: 600;
  color: #22a5ad;
  font-size: 18px;
  margin: 10px;
  text-align: left;
  display: flex;
  align-items: center;
}

.album-row {
  display: grid;
  grid-template-columns: repeat(3, 1fr); /* 3 items per row */
  gap: 30px;
  justify-content: start; /* Move items to the left side */
  margin-bottom: 20px;
  width: 900px;
}

.album-item {
  padding: 25px;
  border: 1px solid #ddd; /* Light border around items */
  border-radius: 8px;
  text-align: center;
  cursor: pointer;
  transition: background-color 0.3s ease;
  box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.05); /* Subtle shadow */
}

.album-item:hover {
  background-color: #dcf4f9;
}

@media (max-width: 768px) {
  .album-row {
    grid-template-columns: repeat(1, 1fr);
  }
}

@media (max-width: 480px) {
  .album-row {
    grid-template-columns: 1fr;
  }
}

/* List Group */
.list-group {
  list-style: none;
  padding-left: 0;
}

/* Style for each list item */
.list-group-item {
  cursor: pointer;
  transition: background-color 0.3s ease, box-shadow 0.3s ease;
  padding: 15px; /* Increase padding for better spacing */
  margin-bottom: 10px; /* Add space between items */
  font-size: 16px;
  font-family: "Montserrat", sans-serif;
  color: #333;
  border: 1px solid #c2c2c2; /* Light border */
  border-radius: 8px; /* Rounded corners */
  background-color: #fff; /* White background */
  box-shadow: 0 2px 6px rgba(0, 0, 0, 0.1); /* Soft shadow */
  text-align: center; /* Center the content */
  width: 700px; /* Fixed width for each item */
  height: 50px; /* Fixed height for each item */
  display: flex; /* Flexbox for centering */
  flex-direction: column; /* Stack items vertically */
  justify-content: center; /* Center content vertically */
  align-items: center; /* Center content horizontally */
  transition: transform 0.2s ease; /* Smooth hover transition */
}

/* Hover effect */
.list-group-item:hover {
  background-color: #e0f7fa; /* Change background on hover */
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.2); /* Increase shadow on hover */
  transform: translateY(-5px); /* Slight lift on hover */
}

/* Album */
.album h2 {
  font-size: 22px;
  margin-bottom: 20px;
}


/* MP3 Player */
.mp3-player {
  padding-bottom: 100px;
}

/* Letter Circle */
.letter-circle {
  display: inline-block;
  width: 40px;
  height: 40px;
  border-radius: 50%; /* Circle shape */
  border: 1px solid #22a5ad; /* Border color */
  color: #22a5ad; /* Text color */
  font-size: 18px;
  font-weight: 600;
  text-align: center;
  line-height: 40px; /* Vertically center text */
  margin-right: 10px; /* Space between circle and text */
  font-family: "Montserrat", sans-serif;
}
